<template>
  <div>
    <AlertWarning v-if="!blocked">
      <p>Загрузка скана осуществляется после блокировки</p>
    </AlertWarning>
    <!--          :loading="downloadNow"-->
    <v-btn
      :disabled="downloadNow"
      class="text-none pa-3"
      color="#EB722E"
      @click="printFile"
      v-if="blocked"
    >
      <span class="nav-item-kcp_title">
        {{ isRegion ? 'Печать предложения по целевой квоте субъекта РФ' : 'Печать предложения по целевой квоте' }}
      </span>
    </v-btn>
    <div v-if="blocked" class="file mt-4">
      <div class="file-view">
        <div class="file-view__name">
          <span v-html="item.name"></span>
          <button
            v-if="alreadyUploadedFile.length !== 0 && alreadyUploadedFile[0].fileId"
            class="file-view__download-file" @click="getUploadedScanView"
          >
            просмотр файла
            <v-progress-circular
              v-if="uploadedFileLoading"
              color="#eb722e"
              indeterminate
              size="10"
              width="2"
            ></v-progress-circular>
          </button>
        </div>
        <div class="file-view__actions mt-2">
          <button
            v-if="!modeEdit" :class="{'button_border_important' : alreadyUploadedFile.length === 0}"
            class="button_border file-view__button_replace" @click="modeEdit = !modeEdit">
            {{ alreadyUploadedFile.length !== 0 && alreadyUploadedFile[0].fileId ? 'Заменить' : 'Загрузить' }}
          </button>
        </div>
      </div>
      <div v-if="modeEdit" class="file-edit">
        <v-autocomplete
          v-model="idTypeUploadedFile"
          :items="typeUploadedFiles"
          placeholder="Выберите вид прилагаемого файла"
          clearable
          dense
          no-data-text="По вашему запросу ничего нет"
        ></v-autocomplete>
        <v-file-input v-model="fileToUpload" :rules="rules" accept="application/pdf" label="Выбрать файл pdf..."/>
        <button
          v-if="validate"
          :disabled="(!canUpload || uploadToServNow) || !idTypeUploadedFile "
          class="button_border button_border border_margin_right"
          @click="uploadFile"
        >Отправить предложение
          <v-progress-circular
            v-if="uploadToServNow"
            color="#eb722e"
            indeterminate
            size="10"
            width="2"
          ></v-progress-circular>
        </button>
        <button class="button_border ml-2" @click="modeEdit = !modeEdit">Отмена</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {mapActions, mapGetters} from "vuex";
import AlertWarning from "@/components/alerts/AlertWarning";
import request from "@/services/request";

export default {
  name: "Print",
  components: {AlertWarning},
  data() {
    return {
      item: {
        name: 'ВНИМАНИЕ!!! Скан должен содержать формы, которые выходят на печать, подписанные руководителем организации с печатью.<br>' +
          'Скан грузится одним pdf-файлом размером до 50 МБ', fileType: ''
      },
      downloadNow: false,
      fileToUpload: null,
      blocked: false,
      modeEdit: false,
      uploadedFileLoading: false,
      validate: null,
      idTypeUploadedFile: null,
      alreadyUploadedFile: [],
      scanId: null,
      typeUploadedFiles: [
        {value: 'MAIN_SCAN_2022', text: 'Предложение по целевой квоте'},
        {value: 'LETTER_OUT_OF_SCAN_2022', text: 'Письмо об отсутствии предложений по целевой квоте от субъекта'},
      ],
      uploadToServNow: false,
      rules: [
        value => !value || value.size < 20000000 || 'Размер файла не должен превышать 50 МБ!',
        value => !value || value.type === 'application/pdf' || 'Допустимы файлы только в pdf формате',
        value => {
          if (!value) {
            this.validate = null;
            return false;
          } else if (value.type === 'application/pdf') {
            this.validate = true;
            return true;
          } else {
            this.validate = false;
            return false;
          }
        }
      ],
      canUpload: true,
    }
  },
  computed: {
    ...mapGetters('keycloak', {
      getAuthData: 'getAuthData',
      getUser: 'getUser',
      getUserRoles: 'getUserRoles',
      isRegion: 'isRegion'
    }),
  },
  mounted() {
    this.getStatus();
    // this.xxx();
    this.getUploadedScan();
  },
  methods: {
    ...mapActions('notifications', {addNotification: 'addNotification'}),
    
    async getStatus() {
      try {
        const res = await request({
          endpoint: `/api/get/user/username?username=${this.getUser.preferred_username}`,
          method: 'get',
          token: `${this.getAuthData.token}`
        });
        if (res.status === 200 && res.data) {
          this.userId = res.data.userId;
          if (res.data.userStatus === 'BLOCKED_FOR_PRINT') {
            this.blocked = true
          }
          console.log('getStatus успех');
        }
      } catch (e) {
        console.log(e);
      }
    },
    
    //печать файла /////////////////////////////////////////
    async printFile() {
      this.downloadNow = true;
      let uri;
      if (this.isRegion) {
        uri = `/api/reports/getMainTargetReportNew/${this.getUser.regionID}`
      } else {
        uri = `/api/reports/getMainTargetReportFoivNew/${this.getUser.founderID}`
      }
      let responseAxios = await axios.get(uri, {
        responseType: 'blob',
        token: `${this.getAuthData.token}`
      })
      this.downloadNow = false;
      this.saveByteArray("Report.pdf", responseAxios.data)
    },
    
    saveByteArray(reportName, byte) {
      let blob = new Blob([byte], {type: "application/pdf"});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = reportName;
      link.click();
    },
    
    async getUploadedScan() {
      try {
        let uri;
        if (this.isRegion) {
          uri = `/api/moduledatagather/scan?regionId=${this.getUser.regionID}`
        } else {
          uri = `/api/moduledatagather/scan?founderId=${this.getUser.founderID}`
        }
        const res = await request({
          endpoint: uri,
          method: 'get',
          token: `${this.getAuthData.token}`,
        });
        if (res.status === 200 && res.data) {
          this.alreadyUploadedFile = res.data.filter(el => el.scanType === 'MAIN_SCAN_2022' || el.scanType === 'LETTER_OUT_OF_SCAN_2022')
        }
      } catch (e) {
        console.log(e);
      }
    },
    
    async uploadFile() {
      if (this.fileToUpload.size < 20000000) {
        this.uploadToServNow = true;
        if (this.fileToUpload && this.validate && this.idTypeUploadedFile) {
          if (this.alreadyUploadedFile.length !== 0) {
            const deleteResponse = await request({
              endpoint: `/api/file/scan/delete?fileId=${this.alreadyUploadedFile[0].fileId}`,
              method: 'GET',
              token: `${this.getAuthData.token}`
            });
            if (deleteResponse.status === 200) {
              console.log('удаление загруженного скана прошло успешно')
              const data = new FormData();
              data.append('file', this.fileToUpload)
              data.append('scanType', this.idTypeUploadedFile)
              let uri;
              if (this.isRegion) {
                data.append('regionId', this.getUser.regionID)
                uri = 'api/collection/file/upload'
              } else {
                data.append('founderId', this.getUser.founderID)
                uri = 'api/collection/file_foiv/upload';
              }
              const responseAxios = await axios.post(uri, data,
                {
                  token: `${this.getAuthData.token}`
                })
              if (responseAxios.status === 200) {
                console.log('добавление скана прошло успешно')
                await this.addNotification({
                  notificationStatus: 'success',
                  notificationMessage: 'Файл успешно загружен'
                })
                this.uploadToServNow = false;
                await this.$router.push('/contact'); // костыль
                await this.$router.push('/print');
              } else {
                this.uploadToServNow = false;
                await this.addNotification({notificationStatus: 'error', notificationMessage: 'Ошибка загрузки файла'})
              }
            }
          } else {
            const data = new FormData();
            data.append('file', this.fileToUpload)
            data.append('scanType', this.idTypeUploadedFile)
            let uri;
            if (this.isRegion) {
              data.append('regionId', this.getUser.regionID)
              uri = 'api/collection/file/upload';
            } else {
              data.append('founderId', this.getUser.founderID)
              uri = 'api/collection/file_foiv/upload';
            }
            const responseAxios = await axios.post(uri, data,
              {
                token: `${this.getAuthData.token}`
              })
            if (responseAxios.status === 200) {
              await this.addNotification({notificationStatus: 'success', notificationMessage: 'Файл успешно загружен'})
              this.uploadToServNow = false;
            } else {
              await this.addNotification({notificationStatus: 'error', notificationMessage: 'Ошибка загрузки файла'})
              this.uploadToServNow = false;
            }
            await this.$router.push('/contact'); // костыль
            await this.$router.push('/print');
          }
        }
      } else
        this.canUpload = false
    },
    
    async getUploadedScanView() {
      this.uploadedFileLoading = true;
      this.downloadNow = true;
      let responseAxios = await axios.get(`/api/moduledatagather/scan/get?scanId=${this.alreadyUploadedFile[0].fileId}`, {
        responseType: 'blob',
        token: `${this.getAuthData.token}`
      })
      this.downloadNow = false;
      this.saveByteArray("ScanView.pdf", responseAxios.data);
      this.uploadedFileLoading = false;
    },
  },
}
</script>

<style scoped>

</style>