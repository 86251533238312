<template>
  <div>
    <Print v-if="getUserRoles.find(el => el === 'FOUNDER_ROLE' || el === 'REGION_ROLE')"></Print>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import Print from "@/views/private/foivData/Print";

export default {
  name: "ViewPrint",
  components: {Print},
  computed: {
    ...mapGetters('keycloak', {
      getAuthData: 'getAuthData',
      getUser: 'getUser',
      getUserRoles: 'getUserRoles',
      isRegion: 'isRegion'
    }),
  },
}
</script>

<style scoped>

</style>